import React from 'react';
import {Row,Col,FormControl,Table,InputGroup,Button, OverlayTrigger, Popover, Spinner} from 'react-bootstrap';
import {Trash, CloudArrowUp} from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import DateTime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import 'moment/locale/pl';
import axios from 'axios';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import compareAsc from 'date-fns/compareAsc';

class ComTabEnergy extends React.Component
{
    state =
    {
        requestSending : false,
        enabledTemp: false,
        enabledSend: false,
    }

    date_start = 0;
    date_end = 0;
    set_temp = 21;

    deleteRent = (rent_id) => 
    {
        this.setState({requestSending : true});
        var Data = 
        {
            id: rent_id, 
        };
        console.log(localStorage.getItem('token'));
        axios.post(`https://backend-seastead.red-electronics.pl/service/deleteRent`, Data, {
            headers: {
                Authorization: localStorage.getItem('token'),
            }})
        .then(res => {
            this.setState({requestSending : false});
            if(res.data==="OK")
            {
                alert("Wynajem usunięty poprawnie.");
                this.props.callReloadData();
            }
        }).catch(err => {
            this.setState({requestSending : false});
            console.log(err);
            this.props.navigate('/login')
        })
        
    }

    addRent = (date_start, date_end, set_temp) =>
    {
        var DateTimeNow = new Date();
        var DateTimeNowString = DateTimeNow.getFullYear() + "-" + (DateTimeNow.getMonth() + 1) + "-" + DateTimeNow.getDate() + " " + DateTimeNow.getHours() + ":" + DateTimeNow.getMinutes() + ":" + DateTimeNow.getSeconds();
        var Data = 
        {
            house_id: this.props.house_id.toString(), 
            date_start: date_start.toString(), 
            date_end: date_end.toString(), 
            start_temp: set_temp, 
            login : this.props.user, 
            date_add: DateTimeNowString.toString(),
        };
        this.setState({requestSending : true});
        this.setState({enabledSend: false});
        axios.post(`https://backend-seastead.red-electronics.pl/service/addRent`, Data, {
            headers: {
                Authorization: localStorage.getItem('token'),
            }})
        .then(res => {
            this.setState({requestSending : false});
            this.setState({enabledSend: true});
            if(res.data==="OK")
            {
                alert("Wynajem dodany poprawnie.");
                this.props.callReloadData();
            }
        }).catch(err => {
            console.log(err);
            this.setState({requestSending : false});
            this.setState({enabledSend: true});
            this.props.navigate('/login')
        });
    }

    render()
    {

        var rentList = this.props.rentHistoryList.map((item) => 
        <tr href="#" key={item.id}> 
            <td>{item.id}</td>
            <td>{item.date_start}</td>
            <td>{item.date_end}</td>
            <td>{item.start_temp}</td>
            <td>{item.login}</td>
            <td>{item.date_add}</td>
            <td style={{textAlign:"center"}}>
                <OverlayTrigger
                trigger="click"
                key={"left"}
                placement={"left"}
                overlay={
                    <Popover id={`popover-positioned-left`}>
                    <Popover.Header as="h3">{`Wymagane potwierdzenie`}</Popover.Header>
                    <Popover.Body style={{textAlign:"center"}}>
                        <strong>Tej operacji nie można cofnąć</strong>
                        <br></br>
                        <Button variant="danger" onClick={() => this.deleteRent(item.id)}>Usuń</Button>
                    </Popover.Body>
                    </Popover>
                }
                >
                <Button variant="danger" disabled={this.state.requestSending}><Trash/></Button>
                </OverlayTrigger>
            </td>
        </tr>);

        return (
            <React.Fragment>
                <h1 style={{textAlign: 'left'}}>Dodaj wynajem</h1>
                <br></br>
                <div>
                    <Row>
                        <Col>
                            <h3 style={{textAlign: 'left'}}>Data od</h3>
                            <InputGroup className="mb-3">
                                <DateTime className="DateTimeField" id='dateTimePicker1' onChange={(time) => 
                                    {
                                        this.date_start = format(parseISO(time._d.toISOString()), 'yyyy-MM-dd HH:mm');
                                        console.log(this.date_start);
                                        console.log(this.date_end);
                                        console.log(parseISO(this.date_start));
                                        console.log(parseISO(this.date_end));

                                        if(compareAsc(parseISO(this.date_start), parseISO(this.date_end)) === -1)
                                        {
                                            this.setState({enabledSend: true});
                                        }
                                        else
                                        {
                                            this.setState({enabledSend: false});
                                        }
                                    }}/>
                            </InputGroup>
                        </Col>
                        <Col>
                        <h3 style={{textAlign: 'left'}}>Data do</h3>
                            <InputGroup className="mb-3">
                                <DateTime className="DateTimeField" id='dateTimePicker2'  onChange={(time) => 
                                    {
                                        this.date_end = format(parseISO(time._d.toISOString()), 'yyyy-MM-dd HH:mm');
                                        console.log(this.date_start);
                                        console.log(this.date_end);
                                        console.log(parseISO(this.date_start));
                                        console.log(parseISO(this.date_end));

                                       if(compareAsc(parseISO(this.date_start), parseISO(this.date_end)) === -1)
                                        {
                                            this.setState({enabledSend: true});
                                        }
                                        else
                                        {
                                            this.setState({enabledSend: false});
                                        }  
                                    }}/>
                            </InputGroup>
                        </Col>
                        <Col>
                            <h3 style={{textAlign: 'left'}}>Temperatura</h3>
                            <InputGroup>
                                <div style={{width:"40%"}}>
                                <FormControl size="bg" id="1" type="number" defaultValue={21} disabled={this.state.enabledTemp} onChange={(temp) => {
                                    this.set_temp = temp.target.value;
                                }}/></div>
                                <InputGroup.Text>{"\xB0C"}</InputGroup.Text>
                            </InputGroup>
                        </Col>
                        <Col>
                            <h3 style={{textAlign: 'left'}}>Potwierdź</h3>
                            <Button variant="primary" style={{textAlign: 'right'}} id="button-addon4" disabled={!this.state.enabledSend} onClick={() => {this.addRent(this.date_start, this.date_end, this.set_temp)}}>
                            {<Spinner animation="border" size="sm" variant="light" hidden={!this.state.requestSending}/>}
                            {(this.state.requestSending === false) ? "Dodaj wynajem " : " Wysyłanie... "}
                            <CloudArrowUp/>
                            </Button>
                        </Col>
                    </Row>

                </div>
                <br></br>
                <h1 style={{textAlign: 'left'}}>Historia wynajmu <Spinner animation="border" hidden={!this.state.requestSending} variant="primary" /></h1>
                <br></br>
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                        <th>ID</th>
                        <th>Od</th>
                        <th>Do</th>
                        <th>Przyg.temp</th>
                        <th>Dodane przez</th>
                        <th>Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rentList}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
}

export default ComTabEnergy;