import React from 'react';
import {Row,Col,Form, Button, Spinner} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ComTabEnergyChart from './ComTabEnergyChart';
import { LightningFill, CloudArrowUp } from 'react-bootstrap-icons';
import axios from 'axios';

class ComTabEnergy extends React.Component
{
    constructor(props) {
        super(props);
        this.state = 
        {
            requestSending : false,
            output1Visible : true,
            output2Visible : true,
            output3Visible : true,
            output4Visible : true,
            output5Visible : true,
            output6Visible : true,
            output7Visible : true,
            output8Visible : true,
            output9Visible : true,
            output10Visible : true,
            outputStates:
            {
                output1on : true,
                output2on : true,
                output3on : true,
                output4on : true,
                output5on : true,
                output6on : true,
                output7on : true,
                output8on : true,
                output9on : true,
                output10on : true,
            }
        };
      }

    sumEnergy = () => 
    {
        var sum = 0;
        var temp = 0;
        for(let i = 1; i<=10; i++)
        {
            temp = 0;
            temp = this.props.chartEnergy["output" + i].map(item => item.y).reduce((prev, curr) => prev + curr, 0);
            sum+=temp;
            //this.props.chartEnergy.map(({x,y}) => sum+=y);
        }
        
        return sum;
    }

    render()
    {
        return (
            <React.Fragment>
                <h1 style={{textAlign: 'left'}}>Energia</h1>
                <br></br>
                <Row>
                    <Col md={3}>
                        <br></br>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check inline type="checkbox" defaultChecked={this.state.output1Visible} label="Boiler" onChange={() => this.setState((prevState) => ({output1Visible:!prevState.output1Visible}))}/>
                                {Object.keys(this.props.choosenHouse).length > 0 ? <Form.Check  inline type="switch" checked={Object.keys(this.props.choosenHouse).length > 0 ? this.props.choosenHouse.output1 : true}  id="custom-switch" onChange={(e) => 
                                {
                                    this.props.changeChoosenHouseData("output1", e.target.checked);
                                    //this.props.choosenHouse.output1 = e.target.checked;
                                }}/> : <Spinner animation="border" variant="primary" />}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check inline type="checkbox" defaultChecked={this.state.output2Visible} label="Klimatyzacja" onChange={() => this.setState(prevState=> ({output2Visible:!prevState.output2Visible}))}/>
                                {Object.keys(this.props.choosenHouse).length > 0 ? <Form.Check inline type="switch" checked={Object.keys(this.props.choosenHouse).length > 0 ? this.props.choosenHouse.output2 : true} id="custom-switch" onChange={(e) => 
                                {
                                    this.props.changeChoosenHouseData("output2", e.target.checked);
                                    //this.props.choosenHouse.output2 = e.target.checked;
                                }}/> : <Spinner animation="border" variant="primary" />}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check inline type="checkbox" defaultChecked={this.state.output3Visible} label="Sypialnia P" onChange={() => this.setState(prevState=> ({output3Visible:!prevState.output3Visible}))}/>
                                {Object.keys(this.props.choosenHouse).length > 0 ? <Form.Check inline type="switch" checked={Object.keys(this.props.choosenHouse).length > 0 ? this.props.choosenHouse.output3 : true} id="custom-switch" onChange={(e) => 
                                {
                                    this.props.changeChoosenHouseData("output3", e.target.checked);
                                    //this.props.choosenHouse.output3 = e.target.checked;
                                }}/> : <Spinner animation="border" variant="primary" />}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check inline type="checkbox" defaultChecked={this.state.output4Visible} label="Sypialnia L" onChange={() => this.setState(prevState=> ({output4Visible:!prevState.output4Visible}))}/>
                                {Object.keys(this.props.choosenHouse).length > 0 ? <Form.Check inline type="switch" checked={Object.keys(this.props.choosenHouse).length > 0 ? this.props.choosenHouse.output4 : true} id="custom-switch" onChange={(e) => 
                                {
                                    this.props.changeChoosenHouseData("output4", e.target.checked);
                                    //this.props.choosenHouse.output4 = e.target.checked;
                                }}/> : <Spinner animation="border" variant="primary" />}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check inline type="checkbox" defaultChecked={this.state.output5Visible} label="Kuchnia" onChange={() => this.setState(prevState=> ({output5Visible:!prevState.output5Visible}))}/>
                                {Object.keys(this.props.choosenHouse).length > 0 ? <Form.Check inline type="switch" checked={Object.keys(this.props.choosenHouse).length > 0 ? this.props.choosenHouse.output5 : true} id="custom-switch" onChange={(e) => 
                                {
                                    this.props.changeChoosenHouseData("output5", e.target.checked);
                                    //this.props.choosenHouse.output5 = e.target.checked;
                                }}/> : <Spinner animation="border" variant="primary" />}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check inline type="checkbox" defaultChecked={this.state.output6Visible} label="AGD" onChange={() => this.setState(prevState=> ({output6Visible:!prevState.output6Visible}))}/>
                                {Object.keys(this.props.choosenHouse).length > 0 ? <Form.Check inline type="switch" checked={Object.keys(this.props.choosenHouse).length > 0 ? this.props.choosenHouse.output6 : true} id="custom-switch" onChange={(e) => 
                                {
                                    this.props.changeChoosenHouseData("output6", e.target.checked);
                                    //this.props.choosenHouse.output6 = e.target.checked;
                                }}/> : <Spinner animation="border" variant="primary" />}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check inline type="checkbox" defaultChecked={this.state.output7Visible} label="Taras" onChange={() => this.setState(prevState=> ({output7Visible:!prevState.output7Visible}))}/>
                                {Object.keys(this.props.choosenHouse).length > 0 ? <Form.Check inline type="switch" checked={Object.keys(this.props.choosenHouse).length > 0 ? this.props.choosenHouse.output7 : true} id="custom-switch" onChange={(e) => 
                                {
                                    this.props.changeChoosenHouseData("output7", e.target.checked);
                                    //this.props.choosenHouse.output7 = e.target.checked;
                                }}/> : <Spinner animation="border" variant="primary" />}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check inline type="checkbox" defaultChecked={this.state.output8Visible} label="Pompy" onChange={() => this.setState(prevState=> ({output8Visible:!prevState.output8Visible}))}/>
                                {Object.keys(this.props.choosenHouse).length > 0 ? <Form.Check inline type="switch" checked={Object.keys(this.props.choosenHouse).length > 0 ? this.props.choosenHouse.output8 : true} id="custom-switch" onChange={(e) => 
                                {
                                    this.props.changeChoosenHouseData("output8", e.target.checked);
                                    //this.props.choosenHouse.output8 = e.target.checked;
                                }}/> : <Spinner animation="border" variant="primary" />}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check inline type="checkbox" defaultChecked={this.state.output9Visible} label="Wyjście 9" onChange={() => this.setState(prevState=> ({output9Visible:!prevState.output9Visible}))}/>
                                {Object.keys(this.props.choosenHouse).length > 0 ? <Form.Check inline type="switch" checked={Object.keys(this.props.choosenHouse).length > 0 ? this.props.choosenHouse.output9 : true} id="custom-switch" onChange={(e) => 
                                {
                                    this.props.changeChoosenHouseData("output9", e.target.checked);
                                    //this.props.choosenHouse.output9 = e.target.checked;
                                }}/> : <Spinner animation="border" variant="primary" />}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check inline type="checkbox" defaultChecked={this.state.output10Visible} label="Wyjście 10" onChange={() => this.setState(prevState=> ({output10Visible:!prevState.output10Visible}))}/>
                                {Object.keys(this.props.choosenHouse).length > 0 ? <Form.Check inline type="switch" checked={Object.keys(this.props.choosenHouse).length > 0 ? this.props.choosenHouse.output10 : true} id="custom-switch" onChange={(e) => 
                                {
                                    this.props.changeChoosenHouseData("output10", e.target.checked);
                                    //this.props.choosenHouse.output10 = e.target.checked;
                                }}/> : <Spinner animation="border" variant="primary" />}
                            </Form.Group>
                        </Form>
                        <Button variant="primary" style={{textAlign: 'left'}} disabled={this.state.requestSending} id="button-addon4" onClick={() => 
                            {
                                this.setState({requestSending : true});
                                axios.post(`https://backend-seastead.red-electronics.pl/service/toggleOutputStates`, this.props.choosenHouse, {
                                    headers: {
                                        Authorization: localStorage.getItem('token'),
                                    }}).then(res => {if(res.data==="OK"){alert("Konfiguracja wysłana poprawnie"); this.setState({requestSending : false});}}).catch(err => {alert("Coś poszło nie tak. Spróbuj jeszcze raz.\nBłąd:" + err); this.setState({requestSending : false});});
                            }}>
                            {<Spinner animation="border" size="sm" variant="light" hidden={!this.state.requestSending}/>}
                            {(this.state.requestSending === false) ? "Zapisz zmiany" : " Wysyłanie... "}
                            <CloudArrowUp/>
                        </Button>
                    </Col>
                    <Col>
                        <ComTabEnergyChart 
                        chartEnergy={this.props.chartEnergy} 
                        output1Visible={this.state.output1Visible}
                        output2Visible={this.state.output2Visible}
                        output3Visible={this.state.output3Visible}
                        output4Visible={this.state.output4Visible}
                        output5Visible={this.state.output5Visible}
                        output6Visible={this.state.output6Visible}
                        output7Visible={this.state.output7Visible}
                        output8Visible={this.state.output8Visible}
                        output9Visible={this.state.output9Visible}
                        output10Visible={this.state.output10Visible}
                        />
                        <br></br>
                        
                        <h3 style={{textAlign: "center"}}><LightningFill style={{color:"rgb(13, 110, 253)"}}/> Całkowite zużycie energii: {this.sumEnergy()} kWh</h3>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default ComTabEnergy;