import React from 'react';
import AccessController from './components/AccessController';
import Login from './components/Login';
import Profile from './components/Profile';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  var username = "";

  function setUsername(user)
  {
    username = user;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login setUsername = {setUsername}/>} />
          <Route path='/profile' element={<Profile/>} />
          <Route path='/' element={<AccessController />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;