import React, { useState, useEffect } from 'react'
import {Container, Form, Button} from 'react-bootstrap';
import {Key} from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router';

function Login() {
    let navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get("https://backend-seastead.red-electronics.pl/service/protected", {
            headers: {
                Authorization: token,
            }
        }).then(res => {
            console.log(res);
            navigate('/');
        }).catch(err => {
            console.log(err);
            navigate('/login');
        })
    }, []);

    const submit = () => {
        console.log(username, password)
        axios.post("https://backend-seastead.red-electronics.pl/service/login", { username, password }).then(user => {
            console.log(user);
            localStorage.setItem('token', user.data.token);
            localStorage.setItem('user', username);
            navigate('/');
        }).catch(err => {
            console.log(err);
            alert(err.response.data.message);
        });
    }
    useEffect(() => { document.body.style.background = "radial-gradient(circle, rgba(5,178,255,1) 0%, rgba(30,30,36,1) 100%)"}, [])
    return (
        <Container fluid>
        <div style={{margin:"0", position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)"}}>
            <h1 style={{textAlign:"center", color:"white"}}>Seastead</h1>
            <h1 style={{textAlign:"center"}}>Dashboard</h1>
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Login</Form.Label>
                    <Form.Control type="text" placeholder="Wprowadź login" value={username} onChange={event => setUsername(event.target.value)}/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Hasło</Form.Label>
                    <Form.Control type="password" placeholder="Wprowadź hasło" value={password} onChange={event => setPassword(event.target.value)} onKeyPress={(e) => {if(e.key==="Enter"){submit()}}}/>
                </Form.Group>
            </Form>
            <br></br>
            <Button variant="success" style={{width:"100%"}} onClick={submit}><Key/> Zaloguj</Button>
        </div>
        </Container>
    )
}

export default Login
