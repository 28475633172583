import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import ControlPanel from './ControlPanel';

function AccessController() {
    let navigate = useNavigate()
    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get("https://backend-seastead.red-electronics.pl/service/protected", {
            headers: {
                Authorization: token,
            }
        }).then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
            navigate('/login')
        })
    }, [])
    return (
        <ControlPanel navigate = {navigate}/>
)
}

export default AccessController